import { PropsWithChildren } from "react";
import styled from '@emotion/styled';

const Container = styled.div`
`;

interface ContentProps {

}

/**
 * Content holder
 * @returns {JSX.Element}
 */
const Content = ({children}:PropsWithChildren<ContentProps>) => {
  return <Container>{children}</Container>;
};

export default Content;