/* eslint-disable @next/next/no-img-element */
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MuiContainer from "@mui/material/Container";
import styled from "@emotion/styled";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Collapse from "@mui/material/Collapse";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import { MenuItem } from "@mui/material";
import { css, Global } from "@emotion/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

/**
 * Site routes
 */
export enum ROUTES {
  FIRST_PAGE = "/",
  SERVICES = "/koulutukset",
  SAFETYCON360 = "/safetycon360",
  COMPANY = "/yritys",
  CONTACT = "/yhteydenotto",
  EXTINGUISHING = "/as1",
  HOTWORK = "/tulityo",
  OCCUPATIONALSAFETY = "/tyoturvallisuuskortti",
  FIRSTAID = "/ensiapu",
  THREAT = "/uhkatilanne",
  EVACUATION = "/evakuointi",
  CHEMICALS = "/kemikaalit",
  FIREALARM = "/paloilmoitin",
  FIRESAFETY = "/paloturvallisuus",
  RISKOFFALLING = "/putoamissuojaus",
  CUSTOMIZED = "/raataloidyt",
  FIRSTAID4H = "/ea4h",
  FIRSTAID8H = "/ea8h",
  FIRSTAID16H = "/ea16h",
}

export interface StyledLinkProps {
  selected: boolean;
  border?: boolean;
  subLink?: boolean;
}

export interface StyledCaretProps {
  isOpen: boolean;
}

const StyledCaret = styled(KeyboardArrowDownIcon)<StyledCaretProps>`
  transform: rotate(-90deg);
  -webkit-transition: -webkit-transform 0.5s;
  -ms-transition: -ms-transform 0.5s;
  transition: transform 0.5s;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(0deg);
    `}
`;

const Container = styled(MuiContainer)`
  display: "flex";
`;

const StyledLink = styled.a<StyledLinkProps>`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
  color: #fff;
  text-decoration: none;
  margin-right: 35px;
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  ${({ selected, theme }) =>
    selected && `color: ${theme.palette.secondary.main};`}

  ${({ border }) =>
    border && {
      border: "2px solid #fff",
      borderRadius: "53px",
      padding: "6px 16px 6px 16px",
    }}

    ${({ subLink }) =>
    subLink && {
      fontWeight: "bold",
      paddingLeft: "30px",
    }}
`;

const StyledMobileLink = styled.a<StyledLinkProps>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 15px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  ${({ selected, theme }) =>
    selected && `color: ${theme.palette.secondary.main};`}

  ${({ border }) =>
    border && {
      border: "2px solid #fff",
      borderRadius: "53px",
      padding: "6px 16px 6px 16px",
    }}
`;

const StyledMobileSubLink = styled.a<StyledLinkProps>`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 5px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  margin-left: 15px;
  ${({ selected, theme }) =>
    selected && `color: ${theme.palette.secondary.main};`};

  ${({ subLink }) =>
    subLink && {
      paddingLeft: "30px",
    }}
`;

const DesktopToolbar = styled<any>(Toolbar)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const MobileToolbar = styled<any>(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const MobileLinkList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  background-color: #000;
`;

const MobileLinkListItem = styled.div`
  display: flex;
  flex: 1;
  padding-top: 8px;
  padding-bottom: 16px;
`;

/**
 * Topbar component
 * @returns {JSX.Element}
 */
const TopBar = () => {
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const router = useRouter();

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoMenu",
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Container>
          <DesktopToolbar style={{ padding: 0 }}>
            <div style={{ flexGrow: 1 }}>
              <Link passHref href={ROUTES.FIRST_PAGE}>
                <a>
                  <img
                    src={require("../../assets/images/Logo-valk.png?resize&size=140&format=webp")}
                    alt="Safetycon logo"
                    width={140}
                    height={50}
                  />
                </a>
              </Link>
            </div>
            <Link passHref href={ROUTES.FIRST_PAGE}>
              <StyledLink selected={router.asPath === ROUTES.FIRST_PAGE}>
                Etusivu
              </StyledLink>
            </Link>
            <Link passHref href={ROUTES.SERVICES}>
              <StyledLink
                {...bindHover(popupState)}
                selected={router.asPath.includes(ROUTES.SERVICES)}
              >
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  Koulutukset <StyledCaret isOpen={popupState.isOpen} />
                </p>
              </StyledLink>
            </Link>
            <HoverMenu
              sx={{ marginLeft: "-35px" }}
              PaperProps={{
                style: {
                  paddingTop: "20px",
                  width: "310px",
                  background: "black",
                  paddingLeft: "20px",
                  paddingBottom: "30px",
                },
              }}
              {...bindMenu(popupState)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <MenuItem>
                <Link passHref href={ROUTES.SERVICES + ROUTES.EXTINGUISHING}>
                  <StyledLink
                    selected={
                      router.asPath === ROUTES.SERVICES + ROUTES.EXTINGUISHING
                    }
                  >
                    Alkusammutus
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link passHref href={ROUTES.SERVICES + ROUTES.HOTWORK}>
                  <StyledLink
                    selected={
                      router.asPath === ROUTES.SERVICES + ROUTES.HOTWORK
                    }
                  >
                    Tulityökortti
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  passHref
                  href={ROUTES.SERVICES + ROUTES.OCCUPATIONALSAFETY}
                >
                  <StyledLink
                    selected={
                      router.asPath ===
                      ROUTES.SERVICES + ROUTES.OCCUPATIONALSAFETY
                    }
                  >
                    Työturvallisuuskortti
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link passHref href={ROUTES.SERVICES + ROUTES.FIRSTAID}>
                  <StyledLink
                    selected={router.asPath.includes(
                      ROUTES.SERVICES + ROUTES.FIRSTAID
                    )}
                  >
                    Ensiapu
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  passHref
                  href={ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID4H}
                >
                  <StyledLink
                    subLink
                    selected={
                      router.asPath ===
                      ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID4H
                    }
                  >
                    EA4H
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  passHref
                  href={ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID8H}
                >
                  <StyledLink
                    subLink
                    selected={
                      router.asPath ===
                      ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID8H
                    }
                  >
                    EA8H
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link
                  passHref
                  href={ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID16H}
                >
                  <StyledLink
                    subLink
                    selected={
                      router.asPath ===
                      ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID16H
                    }
                  >
                    EA16H
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link passHref href={ROUTES.SERVICES + ROUTES.THREAT}>
                  <StyledLink
                    selected={router.asPath === ROUTES.SERVICES + ROUTES.THREAT}
                  >
                    Uhkatilanne
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link passHref href={ROUTES.SERVICES + ROUTES.RISKOFFALLING}>
                  <StyledLink
                    selected={
                      router.asPath === ROUTES.SERVICES + ROUTES.RISKOFFALLING
                    }
                  >
                    Putoamissuojaus
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link passHref href={ROUTES.SERVICES + ROUTES.CHEMICALS}>
                  <StyledLink
                    selected={
                      router.asPath === ROUTES.SERVICES + ROUTES.CHEMICALS
                    }
                  >
                    Vaaralliset aineet
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link passHref href={ROUTES.SERVICES + ROUTES.FIRESAFETY}>
                  <StyledLink
                    selected={
                      router.asPath === ROUTES.SERVICES + ROUTES.FIRESAFETY
                    }
                  >
                    Paloturvallisuus
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link passHref href={ROUTES.SERVICES + ROUTES.EVACUATION}>
                  <StyledLink
                    selected={
                      router.asPath === ROUTES.SERVICES + ROUTES.EVACUATION
                    }
                  >
                    Evakuointi
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link passHref href={ROUTES.SERVICES + ROUTES.FIREALARM}>
                  <StyledLink
                    selected={
                      router.asPath === ROUTES.SERVICES + ROUTES.FIREALARM
                    }
                  >
                    Toiminta paloilmoittimella
                  </StyledLink>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link passHref href={ROUTES.SERVICES + ROUTES.CUSTOMIZED}>
                  <StyledLink
                    selected={
                      router.asPath === ROUTES.SERVICES + ROUTES.CUSTOMIZED
                    }
                  >
                    Räätälöidyt asiantuntijapalvelut
                  </StyledLink>
                </Link>
              </MenuItem>
            </HoverMenu>

            <Link passHref href={ROUTES.SAFETYCON360}>
              <StyledLink selected={router.asPath === ROUTES.SAFETYCON360}>
                Safetycon360
              </StyledLink>
            </Link>
            <Link passHref href={ROUTES.COMPANY}>
              <StyledLink selected={router.asPath === ROUTES.COMPANY}>
                Yritys
              </StyledLink>
            </Link>

            <Link passHref href={ROUTES.CONTACT}>
              <StyledLink border selected={router.asPath === ROUTES.CONTACT}>
                Yhteydenotto
              </StyledLink>
            </Link>
          </DesktopToolbar>

          <MobileToolbar>
            <div style={{ flexGrow: 1 }}>
              <Link passHref href={ROUTES.FIRST_PAGE}>
                <a>
                  <img
                    src={require("../../assets/images/Logo-valk.png?resize&size=105&format=webp")}
                    alt="Safetycon logo"
                    width={96}
                    height={35}
                  />
                </a>
              </Link>
            </div>
            <IconButton
              aria-label="Valikko"
              onClick={() => setShowMenu(!showMenu)}
            >
              <MenuIcon htmlColor="#fff" />
            </IconButton>
          </MobileToolbar>
          {showMenu && (
            <Collapse in={showMenu} sx={{ height: "100vh" }}>
              <Global
                styles={{
                  body: {
                    // Prevent scrolling
                    overflow: "hidden",
                  },
                }}
              />
              <MobileLinkList>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.FIRST_PAGE}>
                    <StyledMobileLink
                      selected={router.asPath === ROUTES.FIRST_PAGE}
                    >
                      Etusivu
                    </StyledMobileLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES}>
                    <StyledMobileLink
                      selected={router.asPath.includes(ROUTES.SERVICES)}
                    >
                      Koulutukset
                    </StyledMobileLink>
                  </Link>
                </MobileLinkListItem>

                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES + ROUTES.EXTINGUISHING}>
                    <StyledMobileSubLink
                      selected={
                        router.asPath === ROUTES.SERVICES + ROUTES.EXTINGUISHING
                      }
                    >
                      Alkusammutus
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES + ROUTES.HOTWORK}>
                    <StyledMobileSubLink
                      selected={
                        router.asPath === ROUTES.SERVICES + ROUTES.HOTWORK
                      }
                    >
                      Tulityökortti
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link
                    passHref
                    href={ROUTES.SERVICES + ROUTES.OCCUPATIONALSAFETY}
                  >
                    <StyledMobileSubLink
                      selected={
                        router.asPath ===
                        ROUTES.SERVICES + ROUTES.OCCUPATIONALSAFETY
                      }
                    >
                      Työturvallisuuskortti
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES + ROUTES.FIRSTAID}>
                    <StyledMobileSubLink
                      selected={router.asPath.includes(
                        ROUTES.SERVICES + ROUTES.FIRSTAID
                      )}
                    >
                      Ensiapu
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link
                    passHref
                    href={ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID4H}
                  >
                    <StyledMobileSubLink
                      subLink
                      selected={
                        router.asPath === ROUTES.SERVICES + ROUTES.FIRSTAID4H
                      }
                    >
                      EA4H
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link
                    passHref
                    href={ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID8H}
                  >
                    <StyledMobileSubLink
                      subLink
                      selected={
                        router.asPath ===
                        ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID8H
                      }
                    >
                      EA8H
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link
                    passHref
                    href={
                      ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID16H
                    }
                  >
                    <StyledMobileSubLink
                      subLink
                      selected={
                        router.asPath ===
                        ROUTES.SERVICES + ROUTES.FIRSTAID + ROUTES.FIRSTAID16H
                      }
                    >
                      EA16H
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES + ROUTES.THREAT}>
                    <StyledMobileSubLink
                      selected={
                        router.asPath === ROUTES.SERVICES + ROUTES.THREAT
                      }
                    >
                      Uhkatilanne
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES + ROUTES.RISKOFFALLING}>
                    <StyledMobileSubLink
                      selected={
                        router.asPath === ROUTES.SERVICES + ROUTES.RISKOFFALLING
                      }
                    >
                      Putoamissuojaus
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES + ROUTES.CHEMICALS}>
                    <StyledMobileSubLink
                      selected={
                        router.asPath === ROUTES.SERVICES + ROUTES.CHEMICALS
                      }
                    >
                      Vaaralliset aineet
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES + ROUTES.FIRESAFETY}>
                    <StyledMobileSubLink
                      selected={
                        router.asPath === ROUTES.SERVICES + ROUTES.FIRESAFETY
                      }
                    >
                      Paloturvallisuus
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES + ROUTES.EVACUATION}>
                    <StyledMobileSubLink
                      selected={
                        router.asPath === ROUTES.SERVICES + ROUTES.EVACUATION
                      }
                    >
                      Evakuointi
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES + ROUTES.FIREALARM}>
                    <StyledMobileSubLink
                      selected={
                        router.asPath === ROUTES.SERVICES + ROUTES.FIREALARM
                      }
                    >
                      Toiminta paloilmoittimella
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SERVICES + ROUTES.CUSTOMIZED}>
                    <StyledMobileSubLink
                      selected={
                        router.asPath === ROUTES.SERVICES + ROUTES.CUSTOMIZED
                      }
                    >
                      Räätälöidyt asiantuntijapalvelut
                    </StyledMobileSubLink>
                  </Link>
                </MobileLinkListItem>

                <MobileLinkListItem>
                  <Link passHref href={ROUTES.SAFETYCON360}>
                    <StyledMobileLink
                      selected={router.asPath === ROUTES.SAFETYCON360}
                    >
                      Safetycon360
                    </StyledMobileLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.COMPANY}>
                    <StyledMobileLink
                      selected={router.asPath === ROUTES.COMPANY}
                    >
                      Yritys
                    </StyledMobileLink>
                  </Link>
                </MobileLinkListItem>
                <MobileLinkListItem>
                  <Link passHref href={ROUTES.CONTACT}>
                    <StyledMobileLink
                      selected={router.asPath === ROUTES.CONTACT}
                      border
                    >
                      Yhteydenotto
                    </StyledMobileLink>
                  </Link>
                </MobileLinkListItem>
              </MobileLinkList>
            </Collapse>
          )}
        </Container>
      </AppBar>
    </Box>
  );
};

export default TopBar;
